@font-face {
    font-family: "century_supra_t4";
    src: url("century_supra_t4_italic.woff2") format("woff2"),
        url("century_supra_t4_italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "century_supra_t4";
    src: url("century_supra_t4_regular.woff2") format("woff2"),
        url("century_supra_t4_regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
